<template>
  <div class="home-container">
    <navbar-container></navbar-container>
    <div class="home-hero">
      <div class="home-container1">
        <h1 class="home-text">Wie funktioniert&apos;s ?</h1>
        <span class="home-text01">
          <span>
            EduNET ist für alle gedacht, die Unterstützung im schulischen Bereich
            suchen. Ganz gleich, ob Sie Schwierigkeiten in einem speziellen Fach
            haben oder Ihr Wissen in einem bestimmten Thema erweitern möchten. Sie
            haben die Möglichkeit, sich entweder als Nachhilfe-Lehrer oder als
            Schüler anzumelden.
          </span>
          <span><span v-html="raw1hqn"></span></span>
          <span><span v-html="rawklgg"></span></span>
        </span>
        <a href="#clip" class="home-link button">Mehr erfahren</a>
      </div>
    </div>
    <div class="home-container2"></div>
    <div class="home-hero1">
      <div class="home-hero-text-container">
        <h1 class="home-heading">Unsere Mission</h1>
        <span class="home-text05">
          Bei EduNET glauben wir fest daran, dass Wissen geteilt werden sollte.
          Deshalb haben wir eine Vermittlungsplattform geschaffen, die es Schülern
          im Kanton Zürich ermöglicht, ihr Wissen miteinander zu teilen. Unser
          Konzept ist einfach: Schüler, die sich in bestimmten Fächern, Themen
          oder Modulen besonders gut auskennen, können sich als Tutoren anmelden.
          Sie bieten ihre Kenntnisse und Erfahrungen an, um anderen Schülern zu
          helfen.
        </span>
      </div>
      <img
        alt="image"
        src="/pexels-tirachard-kumtanom-733856-1500w.jpg"
        class="home-image"
      />
    </div>
    <div class="home-section-separator"></div>
    <div id="About" class="home-about-us">
      <div class="home-heading-container">
        <h2 class="home-text06 Section-Heading">Wer wir sind</h2>
        <span class="home-text07">
          Wir sind ein junges Startup, gegründet von vier aufstrebenden
          Berufsschülern der Technischen Berufsschule Zürich. Bei EduNET teilen
          wir die Leidenschaft für Bildung und die Überzeugung, dass jeder Schüler
          die Möglichkeit verdient, sein volles Potenzial auszuschöpfen.
          <span v-html="raw9yla"></span>
        </span>
      </div>
      <div class="home-visitenkarte1">
        <feature-card
          text="Als Gründer schätze ich die Möglichkeit, Bildungschancen zu verbessern und Schülern den Zugang zu hochwertiger Nachhilfe zu erleichtern. Meine Leidenschaft gilt der Förderung des Lernens und der Bildung"
          Heading="Aleksandar Tomic"
          Position="CEO"
          image_src="/aleks-200h.jpg"
          rootClassName="feature-card-root-class-name4"
        ></feature-card>
        <feature-card
          text="Mir liegt am Herzen, eine Plattform zu schaffen, die Nachhilfelehrer und Schüler effektiv zusammenbringt, um Bildungshürden zu überwinden. Ich bin davon überzeugt, dass Bildung eine transformative Kraft hat."
          Heading="Till Bischof"
          Position="CFO"
          image_src="/till-200h.jpg"
          rootClassName="feature-card-root-class-name5"
        ></feature-card>
      </div>
      <div class="home-visitenkarte11">
        <feature-card
          text="Ich bin stolz darauf, mit diesem Vermittlungsportal eine Lösung anzubieten, die Schülern dabei hilft, ihr schulisches Potenzial auszuschöpfen. Bildung ist der Schlüssel zu einer besseren Zukunft, und ich möchte dazu beitragen."
          Heading="Enea Lippuner"
          Position="COO"
          image_src="/enea-200h.jpg"
          rootClassName="feature-card-root-class-name6"
        ></feature-card>
        <feature-card
          text="Meine Motivation als Teil dieses Projektes liegt darin, Bildung für alle zugänglicher zu machen. Mit diesem Portal möchte ich die Bildungschancen erweitern und dazu beitragen, dass Schüler erfolgreich in ihre Zukunft starten können."
          Heading="Faustino Segura Loza"
          Position="CMO"
          image_src="/faustino-200h.jpg"
          rootClassName="feature-card-root-class-name7"
        ></feature-card>
      </div>
    </div>
    <div class="home-section-separator1"></div>
    <div id="clip" class="home-clip">
      <h2 class="home-text08 Section-Heading">Weitere Informationen</h2>
      <span>Weitere Informationen folgen</span>
      <video
        src
        poster="https://play.teleporthq.io/static/svg/videoposter.svg"
        preload="none"
        class="home-video"
      ></video>
    </div>
    <app-contact text2="+41 79 358 36 68"></app-contact>
  </div>
</template>

<script>
import NavbarContainer from '../components/navbar-container'
import FeatureCard from '../components/feature-card'
import AppContact from '../components/contact'

export default {
  name: 'Home',
  components: {
    NavbarContainer,
    FeatureCard,
    AppContact,
  },
  data() {
    return {
      raw1hqn: ' ',
      rawklgg: ' ',
      raw9yla: ' ',
    }
  },
  metaInfo: {
    title: 'EduNET',
    meta: [
      {
        property: 'og:title',
        content: 'EduNET',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  display: flex;
  padding: 48px;
  position: relative;
  min-height: 80vh;
  align-items: center;
  border-color: #ffffff;
  border-style: hidden;
  border-width: 0px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/unbenannt-3-1500h.gif");
}
.home-container1 {
  display: flex;
  max-width: 1400px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text {
  font-size: 3rem;
  max-width: 25rem;
}
.home-text01 {
  font-size: 1.15rem;
  max-width: 60%;
  margin-top: 48px;
  margin-bottom: 48px;
}
.home-link {
  color: var(--dl-color-gray-white);
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 400;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-unit);
  padding-left: 32px;
  border-radius: 4px;
  padding-right: 32px;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-primary);
}
.home-link:hover {
  transform: scale(1.02);
}
.home-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: hidden;
  border-width: 2px;
}
.home-hero1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: var(--dl-color-gray-text);
  border-style: hidden;
  border-width: 1px;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-hero-text-container {
  width: 35%;
  display: flex;
  align-items: flex-start;
  margin-left: 50px;
  flex-direction: column;
}
.home-heading {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text05 {
  color: var(--dl-color-gray-text70);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-image {
  width: 50%;
  object-fit: contain;
}
.home-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-accent);
}
.home-about-us {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  border-color: var(--dl-color-gray-text);
  border-style: hidden;
  border-width: 1px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.home-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text06 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text07 {
  color: var(--dl-color-gray-text70);
  text-align: center;
}
.home-visitenkarte1 {
  flex: 0 0 auto;
  width: 1183px;
  height: auto;
  display: flex;
  padding: 0px;
  align-self: center;
  align-items: stretch;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  justify-content: center;
}
.home-visitenkarte11 {
  flex: 0 0 auto;
  width: 1183px;
  height: auto;
  display: flex;
  padding: 0px;
  align-self: center;
  align-items: stretch;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  justify-content: center;
}
.home-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-accent);
}
.home-clip {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  border-color: var(--dl-color-gray-text);
  border-style: hidden;
  border-width: 1px;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-text08 {
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-video {
  width: 955px;
  height: 408px;
  display: none;
}
@media(max-width: 991px) {
  .home-hero1 {
    flex-direction: column-reverse;
  }
  .home-hero-text-container {
    width: 80%;
    margin-left: 0px;
  }
  .home-image {
    width: 80%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 767px) {
  .home-hero {
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-text {
    text-align: center;
  }
  .home-text01 {
    max-width: 100%;
    text-align: center;
    padding-left: 48px;
    padding-right: 48px;
  }
  .home-heading {
    text-align: center;
  }
  .home-text05 {
    text-align: center;
  }
  .home-heading-container {
    width: 100%;
  }
  .home-text08 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-link {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero-text-container {
    width: 100%;
  }
  .home-image {
    width: 100%;
  }
  .home-text08 {
    text-align: center;
  }
}
</style>
