<template>
  <div
    data-role="Header"
    class="navbar-container-navbar-container"
    v-bind:class="rootClassName"
  >
    <div class="navbar-container-navbar">
      <img :alt="image_alt" :src="image_src" class="navbar-container-image" />
      <div class="navbar-container-links-container">
        <a href="#About" class="navbar-container-link Anchor">{{ Link }}</a>
        <a href="#clip" class="navbar-container-link1">
          <span class="Anchor">Werbevideo</span>
          <br />
        </a>
        <a href="#contact" class="navbar-container-link2 Anchor">{{ Link1 }}</a>
        <div class="navbar-container-cta-container">
          <button class="navbar-container-cta-btn button">{{ Cta_btn }}</button>
          <div data-role="BurgerMenu" class="navbar-container-burger-menu">
            <svg viewBox="0 0 1024 1024" class="navbar-container-icon">
              <path
                d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div data-role="MobileMenu" class="navbar-container-mobile-menu">
        <div class="navbar-container-top">
          <img
            :alt="image_alt1"
            :src="image_src1"
            class="navbar-container-image1"
          />
          <div data-role="CloseMobileMenu" class="navbar-container-container">
            <svg viewBox="0 0 1024 1024" class="navbar-container-icon02">
              <path
                d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
              ></path>
            </svg>
          </div>
        </div>
        <div class="navbar-container-mid">
          <div class="navbar-container-links-container1">
            <a href="#features" class="navbar-container-link3 Anchor">
              {{ Link2 }}
            </a>
            <span class="navbar-container-link4 Anchor">{{ Link3 }}</span>
            <a href="#about-us" class="navbar-container-link5 Anchor">
              {{ Link4 }}
            </a>
            <a href="#contact" class="navbar-container-link6 Anchor">
              {{ Link5 }}
            </a>
          </div>
          <button class="navbar-container-cta-btn1 Anchor button">
            {{ Cta_btn1 }}
          </button>
        </div>
        <div class="navbar-container-bot">
          <div class="navbar-container-social-links-container">
            <svg
              viewBox="0 0 950.8571428571428 1024"
              class="navbar-container-icon04"
            >
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><svg
              viewBox="0 0 877.7142857142857 1024"
              class="navbar-container-icon06"
            >
              <path
                d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"
              ></path></svg
            ><svg
              viewBox="0 0 877.7142857142857 1024"
              class="navbar-container-icon08"
            >
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavbarContainer',
  props: {
    image_src1: {
      type: String,
      default: '/default-img.svg',
    },
    Link5: {
      type: String,
      default: 'contact',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
    image_src: {
      type: String,
      default: '/logo.svg',
    },
    Cta_btn: {
      type: String,
      default: 'Investor werden',
    },
    Link1: {
      type: String,
      default: 'Kontakt',
    },
    Link3: {
      type: String,
      default: 'services',
    },
    rootClassName: String,
    Cta_btn1: {
      type: String,
      default: 'sTART BUILDING',
    },
    Link2: {
      type: String,
      default: 'features',
    },
    Link4: {
      type: String,
      default: 'About Us',
    },
    image_alt1: {
      type: String,
      default: 'image',
    },
    Link: {
      type: String,
      default: 'Über Uns',
    },
  },
}
</script>

<style scoped>
.navbar-container-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #EDEDED;
}
.navbar-container-navbar {
  width: 100%;
  height: 69px;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-text);
  border-style: hidden;
  border-width: 1px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  background-color: #EDEDED;
}
.navbar-container-image {
  width: 50px;
  object-fit: cover;
}
.navbar-container-links-container {
  display: flex;
  padding: 0px;
  align-self: center;
  align-items: flex-start;
  border-color: rgba(0, 0, 0, 0);
  border-width: 0px;
  flex-direction: row;
  justify-content: flex-start;
}
.navbar-container-link {
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-container-link1 {
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-container-link2 {
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-container-cta-container {
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.navbar-container-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-primary);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.navbar-container-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.navbar-container-icon {
  width: 36px;
  height: 36px;
}
.navbar-container-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.navbar-container-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.navbar-container-image1 {
  width: 50px;
  object-fit: cover;
}
.navbar-container-container {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-container-icon02 {
  width: 24px;
  height: 24px;
}
.navbar-container-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-container-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.navbar-container-link3 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-container-link4 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-container-link5 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-container-link6 {
  text-decoration: none;
}
.navbar-container-cta-btn1 {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-primary);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.navbar-container-bot {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-container-social-links-container {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.navbar-container-icon04 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.navbar-container-icon06 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.navbar-container-icon08 {
  width: 24px;
  height: 24px;
}

@media(max-width: 767px) {
  .navbar-container-links-container {
    display: none;
  }
  .navbar-container-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-tripleunit);
    background-color: var(--dl-color-gray-text);
  }
  .navbar-container-icon {
    fill: var(--dl-color-gray-white);
  }
}
@media(max-width: 479px) {
  .navbar-container-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .navbar-container-burger-menu {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .navbar-container-cta-btn1 {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
}
</style>
