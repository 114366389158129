<template>
  <div class="feature-card-feature-card" v-bind:class="rootClassName">
    <img :alt="image_alt" :src="image_src" class="feature-card-image" />
    <span class="feature-card-heading Card-Heading">{{ Heading }}</span>
    <span class="feature-card-position">{{ Position }}</span>
    <span class="feature-card-text Card-Text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'FeatureCard',
  props: {
    image_alt: {
      type: String,
      default: 'image',
    },
    image_src: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1619548683455-23b17c3ddc30?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&h=1000',
    },
    Heading: {
      type: String,
      default: 'Name',
    },
    rootClassName: String,
    Position: {
      type: String,
      default: 'Position',
    },
    text: {
      type: String,
      default:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.c Sed nisi. Nulla quis sem at nibh elementum imperdiet.',
    },
  },
}
</script>

<style scoped>
.feature-card-feature-card {
  width: 30%;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.feature-card-image {
  width: 100px;
  object-fit: cover;
  margin-bottom: 20px;
}
.feature-card-heading {
  margin-bottom: var(--dl-space-space-halfunit);
}
.feature-card-position {
  color: var(--dl-color-gray-text70);
  font-size: 12px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-tripleunit);
  text-transform: none;
  text-decoration: none;
}
.feature-card-text {
  color: var(--dl-color-gray-text70);
  text-align: center;
}
.feature-card-root-class-name {
  width: 30%;
  margin: 0px;
  align-self: center;
}
.feature-card-root-class-name1 {
  width: 30%;
  align-self: center;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
}
.feature-card-root-class-name2 {
  width: 30%;
  align-self: center;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
}
.feature-card-root-class-name3 {
  width: 30%;
  margin: 0px;
  align-self: center;
}
.feature-card-root-class-name4 {
  width: 30%;
  align-self: center;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
}
.feature-card-root-class-name5 {
  width: 30%;
  align-self: center;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
}
.feature-card-root-class-name6 {
  width: 30%;
  align-self: center;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
}
.feature-card-root-class-name7 {
  width: 30%;
  align-self: center;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
}
@media(max-width: 991px) {
  .feature-card-feature-card {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .feature-card-feature-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 479px) {
  .feature-card-feature-card {
    width: 100%;
  }
}
</style>
